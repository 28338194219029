import PropTypes from 'prop-types';
import ColumnsWrapper from 'libs/storyblok/content/Modules/ColumnsWrapper';
import { columnsHeadingFontKeys } from 'config/branding/columns-header-font-keys';
import ColumnsLayout from '@activebrands/core-web/components/content-layouts/ColumnsLayout';
import CmsDataTransformerBlock from '@activebrands/core-web/libs/storyblok/content/CmsDataTransformerBlock';
import { transformButton } from '@activebrands/core-web/libs/storyblok/utils/transform-buttons';
import transformHeading from '@activebrands/core-web/libs/storyblok/utils/transform-heading';
import { useStyletron } from '@activebrands/core-web/libs/styletron';
import getContentWrapperStyles from '@activebrands/core-web/utils/style-functions/get-content-wrapper-styles';
import getPositionStyles from '@activebrands/core-web/utils/style-functions/get-position-styles';
import ThemeButton from 'components/buttons/ThemeButton';
import Heading from 'components/text/Heading';
import { Link } from 'gatsby';
import { getCollapsibleColumnStyles } from './collapsible-styles';

const TwoColumnsModule = ({ content = {}, isGrid = false }) => {
    const [css] = useStyletron();

    const {
        button = {},
        columns = [],
        heading = [],
        headingStyle = 'md',
        switchDesktopOrder = false,
        verticalAlignment = 'center',
    } = content;

    const columnsLayoutPositionStyles = getPositionStyles({ contentVerticalAlignment: verticalAlignment });

    const headingSettings = transformHeading({ type: heading?.[0]?.type, defaultType: 'h3' });
    const transformedButton = button?.[0] ? transformButton(button?.[0]) : {};
    const contentWrapperStyles = getContentWrapperStyles();

    return (
        <section
            className={css({
                marginBottom: isGrid ? 0 : 'var(--margin-bottom-module)',
                borderRadius: 'var(--border-radius-module)',
                overflow: 'hidden',
                ...contentWrapperStyles,
            })}
        >
            <div
                className={css({
                    alignItems: ['flex-start', null, 'center'],
                    display: 'flex',
                    gap: '16px',
                    justifyContent: 'space-between',
                    marginBottom: '24px',
                    flexDirection: ['column', null, 'row'],
                })}
            >
                {heading.length > 0 && (
                    <Heading fontKeys={columnsHeadingFontKeys[headingStyle]} type={headingSettings.type}>
                        {heading[0]?.text}
                    </Heading>
                )}
                {transformedButton.label && transformedButton.url && (
                    <div className={css({ marginTop: 'auto' })}>
                        <ThemeButton
                            as={Link}
                            rel={transformedButton.rel}
                            theme={transformedButton.theme}
                            to={transformedButton.url}
                        >
                            {transformedButton.label}
                        </ThemeButton>
                    </div>
                )}
            </div>
            <ColumnsLayout
                $style={columnsLayoutPositionStyles}
                columnSizes={[12, null, 6]}
                gutterHeight={8}
                gutterWidth={[8, null, null, 12, null, 16]}
                items={columns.map((column, i) => {
                    const firstColumn = i === 0;

                    return (
                        <ColumnsWrapper
                            $style={{
                                display: 'flex',
                                aspectRatio: 'var(--ratio-module-two-columns)',
                                alignItems: 'center',
                                borderRadius: 'var(--border-radius-module)',
                                overflow: 'hidden',
                                ...getCollapsibleColumnStyles(column.component),
                            }}
                            columnStyle={{
                                order: firstColumn && switchDesktopOrder ? [1, null, null, null, 2] : 1,
                            }}
                            component={column.component}
                            key={column._uid}
                            numberOfItems={columns.length}
                            position={i}
                            switchDesktopOrder={switchDesktopOrder}
                        >
                            <CmsDataTransformerBlock isTwoColumns block={column} />
                        </ColumnsWrapper>
                    );
                })}
            />
        </section>
    );
};

TwoColumnsModule.propTypes = {
    content: PropTypes.object,
    isGrid: PropTypes.bool,
};

export default TwoColumnsModule;
